import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GlobalProvider } from "./context/GlobalContext";
import ErrorBoundary from "./components/ErrorBoundary"; // Import ErrorBoundary
import "./assets/styles.css"; // Global styles

// Import your screens from the correct directory
import FlightInterfaceScreen from "./screens/FlightInterfaceScreen";
import ArrivalsScreen from "./screens/ArrivalsScreen";
import DeparturesScreen from "./screens/DeparturesScreen";

function App() {
  return (
    <GlobalProvider>
      <div className="App">
        <ErrorBoundary>
          <Router>
            <Routes>
              {/* Set up routes for each screen */}
              <Route exact path="/" element={<FlightInterfaceScreen />} />
              <Route path="/arrivals" element={<ArrivalsScreen />} />
              <Route path="/departures" element={<DeparturesScreen />} />
            </Routes>
          </Router>
        </ErrorBoundary>
      </div>
    </GlobalProvider>
  );
}

export default App;
